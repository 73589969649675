import "core-js/modules/es.array.push.js";
import { basePage } from "@/api/api";
export default {
  name: "visitorList",
  data() {
    return {
      tableData: [],
      dataInfo: [],
      Info: null,
      dialogFormVisible: false,
      pageInfo: {
        pageSize: 10,
        name: '',
        current: 1,
        total: 0
      },
      exportIds: '',
      checkMuneSer: [],
      fileUrl: '../../static/file/course.xlsx'
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    reset() {
      this.pageInfo.name = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    getList() {
      basePage(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    showEdit() {
      this.$root.useRouter.push({
        path: "/operators/operatorUser/baseEdit"
      });
    },
    showInfo(id) {
      this.$root.useRouter.push({
        path: "/base/checkBase",
        query: {
          id: id,
          toUrl: '/operators/operatorUser/baseList'
        }
      });
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    }
  }
};